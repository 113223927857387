<template>
  <div v-if="show" class="drawer">
    <el-drawer destroy-on-close :visible.sync="show" size="800px" :show-close="false">
      <div class="drawer-show">
        <div class="drawer-show-header">
          <div class="drawer-show-header-body">
            <h2 class="fl drawer-show-add-name">
              <a href="javascript:void(0);" @click="copyName" class="copy-a" :data-clipboard-text="name">{{ name }}</a>
            </h2>
            <div class="fr">
              <el-button class="drawer-show-add-close" type="text" icon="el-icon-close" @click="show = false" />
            </div>
          </div>
        </div>
        <div class="drawer-show-body">
          <div style="margin-top: 20px">
            <el-tabs v-model="activeName" type="border-card">
              <el-tab-pane label="基本信息" name="基本信息">
                <div class="drawer-item">
                  <p class="drawer-item-name">仓库</p>
                  {{ data.spaceName }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">盘库时间</p>
                  {{ data.inventoryDate }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">备注</p>
                  {{ data.note }}
                </div>
                <vxe-table align="center" :data="data.details">
                  <template #empty>
                    <el-empty></el-empty>
                  </template>
                  <vxe-table-column field="productName" title="产品" min-width="100"></vxe-table-column>
                  <vxe-table-column field="locationName" title="货位" min-width="100"></vxe-table-column>
                  <vxe-table-column field="num" title="盘库数量" min-width="100"></vxe-table-column>
                  <vxe-table-column field="note" title="备注" min-width="100"></vxe-table-column>
                </vxe-table>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      data: {},
      activeName: '基本信息',
      name: '',
    }
  },
  methods: {
    display(data) {
      this.show = true
      this.data = data.data
      this.name = data.data.name
    },
    copyName() {
      let clipboard = new this.Clipboard('.copy-a')
      clipboard.on('success', () => {
        this.$message.success('复制成功')
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        this.$message.error('该浏览器不支持自动复制')
        clipboard.destroy()
      })
    }
  }
}
</script>

