<template>
  <div v-if="show">
    <vxe-modal v-model="show" :title="name" width="700" min-width="700" height="550" min-height="550" showFooter
               esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="data" label-width="auto" label-position="right" :rules="rules">
          <el-form-item prop="name" label="名称">
            <el-input v-model="data.name" placeholder="输入名称" type="text"></el-input>
          </el-form-item>
          <el-form-item prop="spaceNameId" label="仓库">
            <el-select filterable default-first-option v-model="data.spaceNameId" placeholder="选择仓库" style="width: 100%"
                       @change="changeSpaceName">
              <el-option :label="item.spaceName" :value="item.id" v-for="(item, key) in spaceName" :key="key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="note" label="备注">
            <el-input v-model="data.note" placeholder="输入备注" type="textarea"></el-input>
          </el-form-item>
          <el-button type="text" icon="el-icon-folder-add" @click="insertEvent(-1)" style="font-size: 20px">
          </el-button>
          <vxe-table :data="data.details" border algin="left" ref="xTable" show-header-overflow show-overflow
                     style="font-size: 14px; font-weight: bold">
            <vxe-table-column type="seq" title="序号" width="60"></vxe-table-column>
            <vxe-table-column field="productId" title="产品" min-width="150">
              <template v-slot:header>
                <span class="star">*</span>
                <span>产品</span>
              </template>
              <template v-slot="scope">
                <el-form-item label-width="0px" :prop="'details['+scope.$rowIndex+'].productId'"
                              :rules="[{ required: true, message: '请选择产品', trigger: 'change' }]">
                  <el-select v-model="scope.row.productId" placeholder="请选择产品" size="mini" clearable filterable>
                    <el-option v-for="item in product" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </vxe-table-column>
            <vxe-table-column field="locationId" title="货位" min-width="150">
              <template v-slot:header>
                <span class="star">*</span>
                <span>货位</span>
              </template>
              <template v-slot="scope">
                <el-form-item label-width="0px" :prop="'details['+scope.$rowIndex+'].locationId'"
                              :rules="[{ required: true, message: '请选择货位', trigger: 'change' }]">
                  <el-select @focus="focusLocationId()" v-model="scope.row.locationId" placeholder="请选择货位" size="mini" clearable filterable>
                    <el-option v-for="item in location" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </vxe-table-column>
            <vxe-table-column field="num" title="盘库数量" min-width="100">
              <template v-slot:header>
                <span class="star">*</span>
                <span>盘库数量</span>
              </template>
              <template v-slot="scope">
                <el-form-item label-width="0px" :prop="'details['+scope.$rowIndex+'].num'"
                              :rules="[{ required: true, message: '请输入盘库数量', trigger: 'blur' }]">
                  <el-input v-model="scope.row.num" type="number" placeholder="请输入盘库数量" size="mini">
                  </el-input>
                </el-form-item>
              </template>
            </vxe-table-column>
            <vxe-table-column field="note" title="备注" min-width="100">
              <template v-slot:header>
                <span>备注</span>
              </template>
              <template v-slot="scope">
                <el-form-item label-width="0px" :prop="'details['+scope.$rowIndex+'].note'">
                  <el-input v-model="scope.row.note" type="text" placeholder="请输入备注" size="mini">
                  </el-input>
                </el-form-item>
              </template>
            </vxe-table-column>
            <vxe-table-column fixed="right" width="100" title="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" icon="el-icon-delete" @click="getmove(scope)"
                           style="color: red; font-size: 20px"></el-button>
                <el-button type="text" icon="el-icon-folder-add" @click="insertEvent(-1)" style="font-size: 20px">
                </el-button>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      name: '',
      data: {},
      rules: {
        spaceNameId: [{ required: true, trigger: 'change', message: '选择仓库' }],
        name: [{ required: true, trigger: 'blur', message: '输入名称' }],
      },
      loading: false,
      spaceName: [],
      location: [],
      product: [],
    }
  },
  methods: {
    /** 打开界面方法 */
    display(data) {
      this.show = true
      this.data = this.$utils.clone(data.data, true)
      this.data.details = []
      this.name = data.name
      this.spaceName = data.spaceName
      this.product = data.product
      this.$nextTick(() => {
        if (this.$refs.form) this.$refs.form.clearValidate()
      })
    },
    async insertEvent(row) {
      let record = { productId: null }
      this.data.details = this.$refs.xTable.afterFullData
      await this.$refs.xTable.insertAt(record, row)
    },
    getmove(scope) {
      var index = scope.data[scope.$rowIndex]
      this.$refs.xTable.remove(index)
      this.data.details = this.$refs.xTable.afterFullData
    },
    changeSpaceName() {
      this.data.details.forEach(item => {
        this.$set(item, 'locationId', null)
      })
    },
    focusLocationId() {
      this.location = []
      if (['', null, undefined].includes(this.data.spaceNameId)) this.$message.warning('请先选择仓库！')
      else {
        const params = {
          params: {
            spaceNameId: this.data.spaceNameId,
            pageNum: 1,
            pageSize: 10000,
          },
        }
        this.$axios.get('/order/Location/queryList', params).then((res) => {
          if (res) this.location = res.data.data.list
        })
      }
    },
    /** 保存方法 */
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          const afterData = JSON.parse(JSON.stringify(this.$refs.xTable.afterFullData))
          this.data.details = afterData
          if (this.data.details.length === 0 ) {
            this.$message.warning('请增加盘库明细')
            this.loading = false
          } else {
            const params = this.data
            this.$axios
                .post('/order/stock/pan', params)
                .then((res) => {
                  if (res) {
                    this.$message.success('新增成功')
                    this.show = false
                    this.$emit('init')
                  }
                  this.loading = false
                })
                .catch((err) => {
                  console.log('新增失败', err)
                  this.loading = false
                })
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style>
.star {
  color: #f56c6c;
  font-size: 14px;
  margin-right: 4px;
}
</style>